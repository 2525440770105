import React from "react";
import { Link, Outlet } from "react-router-dom";

const Home = () => {
  return (
    <>
      <Outlet />
      <div className="mainBG w-full h-screen flex flex-col items-center justify-center px-5 space-y-20">
        <div>
          <img src="/images/Logo.png" alt="main Logo" />
        </div>
        <div className="sm:w-[700px]">
          <h1 className="text-white sm:text-6xl text-5xl font-medium sm:leading-[70px]">
            Install the app and reserve your class today.
          </h1>
        </div>
        <div className="flex flex-col space-y-8">
          <div>
            <p className="text-white uppercase text-2xl font-normal">
              Download now
            </p>
          </div>
          <div className="flex sm:flex-row flex-col gap-2">
            <button
              onClick={() => {
                window.open("https://testflight.apple.com/join/0J3g3Usj");
              }}
            >
              <img src="/images/AppStore.png" alt="app store" />
            </button>
            <button
              onClick={() => {
                window.open("https://api.thecultrawai.com/api/the_cult.apk");
              }}
            >
              <img src="/images/GoglePlayStore.png" alt="google app" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
